<template>
  <div>
    <b-button
      class="mb-2"
      @click="$router.push('/grupos')"
      variant="primary-custom"
      ><fa-icon icon="arrow-left" class="mr-2"></fa-icon>Voltar</b-button
    >
    <Card :title="'Criar novo grupo'">
      <FormAddEdit class="my-3" />
    </Card>
  </div>
</template>

<script>
export default {
  components: {
    FormAddEdit: () => import("./components/FormAddEdit.vue"),
  },
};
</script>

<style>
</style>